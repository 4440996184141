import React from "react";
import { HeadImage, Consultation, ImageSlider } from "../../components";
import { Story, Team, Footer } from "../../containers";
import images from "../../constants/images";
import { motion } from "framer-motion";
import "./about.css";
// import React from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const About = () => {
  const imagesSlider = [
    images.model1,
    images.picture4,
    images.picture5,
    images.model2,
  ];

  const containerStyles = {
    width: "500px",
    height: "280px",
    margin: "0 auto",
  };
  return (
    <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ Scale: 0 }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      className="app__about"
    >
      <HeadImage title="A Propos" img={images.building7} />
      <Story />
      <Team />
      <div className="team-presentation">
        <div className="team-presentation-box">
          <img src={images.team2} alt="" />
        </div>
      </div>
      <div className="container-carousel">
        <Carousel className="carousel">
          {imagesSlider.map((imageUrl, index) => (
            <div key={index}>
              <div className="carousel-image">
                <img src={imageUrl} alt={`Image ${index + 1}`} />
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <Consultation />
      <Footer />
    </motion.div>
  );
};

export default About;
