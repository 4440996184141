import images from "./images";

const description = [
  {
    link: "/villa1",
    title1: "Villa écologique",
    title2: "en brique de terre",
    category: "Villa",
    specification: "Villa écologique en brique de terre",
    service: "Conception et construction",
    surface: "500 m2",
    dsc: "Un grand Séjour parental, Une grande cuisine, Une reserve, Un bureau, Un séjour pour enfants, Une loge de gardien, Trois chambres, Trois Terrasses, Deux douches, Une toilette visiteur, Une buanderie extérieur",
    img: [images.villa2, images.villa1, images.villa12],
  },
  {
    link: "/villa2",
    title1: "Conception d'une villa",
    title2: "de type F4",
    category: "Villa",
    specification: "Conception d'une villa de type F4",
    service: "Conception et construction",
    surface: "250 m2",
    dsc: "Trois Chambres avec douche dans chacune ,  une grande cuisine, Un grand salon, Une salle à manger, Une cuisine, Un WC visiteur, Une buanderie, Téserve, Trois terrasses",
    img: [images.villa5, images.villa6, images.villa7],
  },
  {
    link: "/villa3",
    title1: "Conception d'une villa",
    title2: "de type F5",
    category: "Villa",
    specification: "Conception d'une villa de type F5",
    service: "Conception et construction",
    surface: "280 m2",
    dsc: "Quatre Chambres avec douche salle d'eau, Une toilette visiteur, Deux terrasses, une avant une arriere, Un grand salon, Une cuisine, Une loge de gardien, Une salle à manger,Trois Terrasses",
    img: [images.villa9, images.villa10, images.villa11],
  },
  {
    link: "/apartment",
    title1: "Appartement",
    title2: "de type F3",
    category: "Appartement",
    specification: "Appartement de type F3",
    service: "Conception et construction",
    surface: "200 m2",
    dsc: "Deux grandes chambres avec placards, Deux douches, ( dont une dans la chambre parentale), Un grand salon, (pouvant contenir séjour + salle à manger), Une cuisine",
    img: [images.apartment1, images.apartment2, images.apartment3],
  },
  {
    link: "/house",
    title1: "Maison plein pieds",
    title2: "de type F5",
    category: "Maison",
    specification: "Maison plein pieds de type F5",
    service: "Conception et construction",
    surface: "200 m2",
    dsc: "Quatre Chambres Une toilette visiteur,Un grand salon,Une salle a mange,Une zone véhicule,Deux Salle deau, Un dressing dans la chambre parent,Une grande cuisine avec magasin, Trois terrasses,",
    img: [images.house1, images.house2],
  },
  {
    link: "/batiment",
    category: "Bâtiment",
    title1: "Bâtiment R+1",
    title2: "Bâtiment R+1",
    specification: "Bâtiment R+1",
    service: "Conception et construction",
    surface: "120 m2",
    dsc: "Cinq Chambres, Trois douches (dont une dans la chambre parentale), Un grand salon, Une salle a mange, Une reserve, Un WC visiteur,  Un cuisine, Un bureau, Trois terrasses ",
    img: [images.btm1, images.btm2],
  },
  {
    link: "/house",
    title1: "Maison plein pieds",
    title2: "de type F5",
    category: "Maison",
    service: "Conception et construction",
    dsc: "Quatre Chambres Une toilette visiteur,Un grand salon,Une salle a mange,Une zone véhicule,Deux Salle deau, Un dressing dans la chambre parent,Une grande cuisine avec magasin, Trois terrasses,",
    img: [images.travaux4, images.travaux2],
  },
  {
    link: "/batiment",
    category: "Bâtiment",
    service: "Conception et construction",
    dsc: "Cinq Chambres, Trois douches (dont une dans la chambre parentale), Un grand salon, Une salle a mange, Une reserve, Un WC visiteur,  Un cuisine, Un bureau, Trois terrasses ",
    img: [images.travaux1, images.travaux3],
  },
];

export default description;
