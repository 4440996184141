import React from "react";
import { HeadText } from "../../components";
import "./serviceOT.css";

const ServiceOT = () => {
  return (
    <div className="app__serviceOT">
      <div className="app__serviceOT-left-box">
        <HeadText title="Travaux TCE" />
        <h3>Votre entreprise générale pour l'exécution de vos chantiers</h3>
        <p>
          Notre service travaux met à disposition une équipe d'hommes et de
          femmes expérimentés. Il regroupe toutes les compétences nécessaires
          pour la réussite de votre projets tous corps d'état, cela, grâce à
          l'expérience de nos ingénieurs, la qualité et le potentiel de nos
          techniciens et partenaires. <br /> De la conception à la réalisation,
          nos équipes réaliseront pour vous, particuliers et entreprises, un
          travail de qualité dans le respect des techniques et des méthodes les
          plus adoptées à votre chantier.
          <br /> <br />
          <span style={{ fontWeight: "bold" }}>
            L'avantage de choisir Ona Batiment ?
          </span>
          <br /> Un interlocuteur unique à l'écoute de vos désirs vous conseille
          sur les différentes options, étudie les faisabilités et vous propose
          les solutions les plus adaptées en tenant compte de vos contraintes.
          <br /> <br />
          <span style={{ fontWeight: "bold" }}>
            Ona Batiment réalise les travaux de Rénovation
          </span>
          : <br /> Que vous envisagez une réhabilitation, une extension ou une
          surélévation, notre entreprise saura vous conseiller, orienter et
          réaliser vos travaux tout en assurant le respect des délais et la
          maîtrise de vos budgets.
          <br /> <br />
          <span style={{ fontWeight: "bold" }}>Qualité</span> <br />
          Notre entreprise est engagée dans une démarche de qualité dont le but
          est la satisfaction de nos clients. Nous proposons des solutions
          adaptées et nous nous engageons à réaliser vos projets dans les règles
          de l'art et respect de la qualité requise. Nous œuvrons
          quotidiennement à maintenir un niveau d'exigence en terme de :
          <br />
          ● Finances <br /> ● Budget <br /> ● Méthodologie
        </p>
      </div>
      <div className="app__serviceOT-right-box">
        <ul>
          <li>Gros oeuvre</li>
          <li>VRD</li>
          <li>Maçonnerie</li>
          <li>Ouvrages "clés en main" </li>
          <li>Peinture</li>
          <li>Plomberie</li>
          <li>Assainissement</li>
          <li>Pose des pavés</li>
          <li>Électricité</li>
        </ul>
      </div>
    </div>
  );
};

export default ServiceOT;
