import React from "react";
import { HeadText } from "../../components";
import images from "../../constants/images";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./serviceOCA.css";

const ServiceOCA = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });

  const variants = {
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1.5,
        ease: "easeOut",
      },
    },
    hidden: {
      opacity: 0,
      x: -100,
    },
  };

  return (
    <div className="app__serviceOCA">
      <div className="app__serviceOCA-first-box">
        <HeadText title="conception architecturale" />
        <p>
          Les prestations de conception architecturale proposées par CONCEPTION
          ARCHI s’adaptent aux besoins les plus ciblés de vos chantiers partout
          dans le Gabon. <br />
          Dans le cadre d’une remise en état, d’une extension ou d’un projet
          neuf, nous réalisons : <br /> <br />
        </p>
        <ul>
          <li>Esquisses et plans d'aménagement</li>
          <li>Des plans 3D de construction</li>
          <li>Des plans de valorisation d’un bâtiment ancien</li>
          <li>Des plans de surélévation d’appartement</li>
          <li>Simulation 3D</li>
          <li>Synthèse des plans</li>
        </ul>
      </div>
      <motion.div
        ref={ref}
        className="animated app__serviceOCA-img-box"
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={variants}
      >
        {/* <img src={images.travaux2} alt="plan images" /> */}
        <img src={images.travaux4} alt="plan images" />
      </motion.div>
      <div className="app__serviceOCA-third-box">
        <HeadText title="Bureau d'études : structure" />
        <p>
          Notre Bureau d’études est spécialisé dans le calcul des ouvrages en
          béton armé. Sur chacune de nos interventions, nous établissons des
          plans de coffrages et d'armatures tout en respectant les ratios
          d'acier et la gestion des interfaces avec les bureaux de contrôle, les
          architectes et les autres corps d'état techniques.
          <br />
          Alliant rigueur et créativité, nos ingénieurs relèvent les différents
          challenges techniques des grands projets. Ils analysent et identifient
          avec précision les contraintes dès la phase d’avant projet tout en
          conseillant et orientant le client et nos partenaires. Nos ingénieurs
          jouissent d’une grande expérience et connaissent les multiples genres
          de structures exploités dans le bâtiment ainsi que les matériaux
          utilisés, qu’il s’agisse de béton, de métal, de bois ou d’une
          association entre ces différents matériaux.
        </p>
        <p>
          Notre professionnalisme, notre engagement et notre sens du service de
          proximité sont la garantie de la qualité de nos missions. Nous
          intervenons dans les domaines d’activités suivants: <br />
        </p>
        <h3>HYPOTHÈSES DE CALCUL</h3>
        <div className="animated service_be-structure-container ">
          {/* <p>La conception, l’optimisation et l'étude de structures :</p>
          <ul>
            <li>Béton armé</li>
            <li>Construction métallique</li>
            <li>Construction en bois</li>
          </ul>
          <p>Les plans de projet et d'exécutions:</p>
          <ul>
            <li>Coffrage</li>
            <li>Armature</li>
            <li>Nomenclatures</li>
          </ul>
          <p>Les calculs de structures :</p>
          <ul>
            <li>Descentes de charges</li>
            <li>Calcul 3D et dynamique</li>
            <li>
              Éditer toute note de calcul de dimensionnement conformément aux
              réglementations en vigueur.
            </li>
          </ul> */}
          <p>
            La définition des hypothèses pour différents facteurs de
            construction : <br /> ● Nous prenons en compte les charges imposées
            par la pose des nouveaux matériaux sur la structure existante. Le
            poids propre, la surcharge permanente et la surcharge d’exploitation
            sont définies selon les normes en vigueur. <br /> ● Pour l’étude du
            sol, plusieurs éléments sont à prendre en compte avec notamment les
            contraintes admissibles, les modes de fondations, le niveau
            d’assise, les risques d’inondations et les tassements différentiels.
            du béton et de l’acier mis en place, l’enrobage et sa classe
            d’exposition. <br />
            <h3>CALCULS DES ÉLÉMENTS</h3> Par la suite, après avoir défini les
            hypothèses relatives au chantier, nous pouvons procéder aux calculs
            des éléments qui seront mis en place dans la structure. Cela prend
            en compte les deux facteurs suivants : <br /> ● Le dimensionnement
            des éléments structurels tout en respectant les critères de
            résistance, de flèches, de fissuration, et d’autres <br /> ● la
            détermination des armatures nécessaires retenues pour les
            dimensionnements d’éléments structurels Nous réalisons les plans de
            ferraillage et de coffrage pour vos structures. <br />
            <h3>EXPERTISE</h3>
            Notre expertise s’étend sur : <br />● Diagnostic de structures
            existantes <br /> ● Etudes d’avant-projet béton armé <br /> ● Etudes
            d’exécution <br /> ● Suivi et vérification de travaux
            <br /> ● Expertise et conseil Nous réalisons ces prestations sur de
            grandes opérations telles que des constructions neuves ou
            réhabilitations lourdes mais aussi de petits projets tels que des
            maisons individuelles ou des créations d’ouverture dans l’existant,
            études d’exécution de surélévation En phase Exécution (EXE) nous
            assurons les missions d’Exécution ou de VISA des plans, afin de
            contrôler le respect du projet par les entreprises.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceOCA;
